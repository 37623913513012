import { useEffect, useState } from "react"
import { css } from "@emotion/react"
import PropTypes, { shape } from "prop-types"
import { sortBy, get as dig } from "lodash"
import { Link, useParams } from "react-router-dom"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import classNames from "classnames"
import { MediaThumbnail } from "source/publishing/sermons/shared/MediaThumbnail"
import { episodePath } from "source/publishing/sermons/shared/routes"
import {
  Date,
  Datetime,
  DayOfWeekAndTimeOfDay,
} from "source/shared/DatetimeFmt"
import { size } from "@planningcenter/system"
import { Error404 } from "source/shared/components/Error404"
import { NotifyNativeLoaded } from "source/shared/hooks/useNotifyNativeLoaded"
import {
  LimitedInfiniteScroll,
  Icon,
  Card,
  CardGrid,
} from "source/shared/components"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { IncludeQRCode } from "source/shared/QRCode"
import { Heading } from "@planningcenter/doxy-web"
import { useQueries, useSuspenseQuery } from "@tanstack/react-query"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"
import { getRelationship } from "source/shared/getRelationship"
import { Helmet } from "react-helmet-async"
import NativeHeader from "./shared/NativeHeader"

const EPISODE_TIME_IS_OVER = "over"
const EPISODE_TIME_IS_ACTIVE = "active"
const EPISODE_TIME_IS_UPCOMING = "upcoming"

const POLL_INTERVAL_IN_MILLISECONDS = 10000

function useChannel(id) {
  const ROLLOUT_episode_speakers = useFlipperFeatureEnabled(
    "ROLLOUT_episode_speakers",
  )
  const url = `/publishing/v2/channels/${id}`
  const include = [
    "current_episode",
    "current_episode.episode_times",
    "current_episode.note_template",
    ROLLOUT_episode_speakers && "current_episode.speakerships",
    "next_times",
    "featured_episodes",
    ROLLOUT_episode_speakers && "featured_episodes.speakerships",
    "featured_series",
  ]
    .filter(Boolean)
    .join(",")
  const params = { include }
  return useSuspenseQuery({
    queryFn: () => {
      return sessionApiClient.get(url, params).catch((error) => error)
    },
    queryKey: ["publishing", "channel", id, params],
    select: function injectSpeakerRelationshipIntoEpisodes(data) {
      data.included.forEach((includedee) => {
        if (includedee.type !== "Episode") return

        const speakerships = getRelationship(
          { data: includedee, included: data.included },
          "speakerships",
        )

        if (!speakerships) return

        includedee.relationships.speaker = {
          data: speakerships
            .map((s) => s?.relationships.speaker.data)
            .filter(Boolean),
        }
      })

      return data
    },
    refetchInterval: (query) =>
      query.state.data?.data && POLL_INTERVAL_IN_MILLISECONDS,
  })
}

function useSpeakers(channelApiResponse) {
  const ROLLOUT_episode_speakers = useFlipperFeatureEnabled(
    "ROLLOUT_episode_speakers",
  )

  const speakerLinks = (channelApiResponse.included || [])
    .filter((i) => i.type === "Speakership")
    .map((s) => s.links.self)

  return useQueries({
    queries: ROLLOUT_episode_speakers
      ? speakerLinks.map((sl) => ({
          queryFn: () => {
            return sessionApiClient
              .get(sl, { include: "speaker" })
              .catch(() => "error")
          },
          queryKey: [
            "publishing",
            "channel",
            channelApiResponse?.data.id,
            "speakership",
            sl.split("/").slice(-1)[0],
          ],
        }))
      : [],
    combine: (responses) => {
      return responses
        .filter((r) => r !== "error")
        .map((r) => r.data.included[0])
    },
  })
}

const extractOneRelationship = (json, name) => {
  const maybe = dig(json, `data.relationships.${name}.data`)

  if (maybe) {
    return json.included.find((i) => i.type === maybe.type && i.id === maybe.id)
  } else {
    return null
  }
}
const extractManyRelationship = (json, name) =>
  dig(json, `data.relationships.${name}.data`, []).map(({ type, id }) =>
    json.included.find((i) => i.type === type && i.id === id),
  )

export function Channel() {
  const { channelId } = useParams()

  const { data: apiResponse } = useChannel(channelId)
  const speakers = useSpeakers(apiResponse)

  const channel = apiResponse.data
  const included = apiResponse.included || []
  const currentOrNextEpisode = extractOneRelationship(
    apiResponse,
    "current_episode",
  )
  const episodeTimes = included.filter(
    (resource) => resource.type === "EpisodeTime",
  )
  const noteTemplate = currentOrNextEpisode?.relationships?.note_template?.data
  const hasNotes =
    channel.attributes.enabled_formats.sermon_notes && !!noteTemplate
  const nextDefaultTimes = included.filter(
    (resource) => resource.type === "ChannelNextTime",
  )

  if (!channel) return <Error404 />

  const channelName = channel.attributes.name
  const activeEpisodeTimes =
    episodeTimes.filter(
      (episodeTime) =>
        episodeTime.attributes.current_state === "active" ||
        episodeTime.attributes.current_state === "upcoming",
    ).length > 0
  const canBeLive =
    channel.attributes.enabled_formats.watch_live ||
    channel.attributes.enabled_formats.sermon_notes
  const noCurrentOrUpcomingEpisodes = canBeLive && !currentOrNextEpisode
  const episodesAreUpcoming =
    canBeLive && currentOrNextEpisode && !activeEpisodeTimes
  const episodeIsLive = canBeLive && currentOrNextEpisode && activeEpisodeTimes
  const featuredSeries = extractManyRelationship(apiResponse, "featured_series")
  const featuredEpisodes = sortBy(
    extractManyRelationship(apiResponse, "featured_episodes"),
    (s) => s.attributes.published_to_library_at,
  ).reverse()
  const featuredEpisode =
    noCurrentOrUpcomingEpisodes && nextDefaultTimes.length === 0
      ? featuredEpisodes.shift()
      : undefined

  const DESIRED_COLUMNS = 3
  while (featuredSeries.length > DESIRED_COLUMNS) featuredSeries.pop()
  while (featuredSeries.length > 0 && featuredEpisodes.length > DESIRED_COLUMNS)
    featuredEpisodes.pop()

  return (
    <>
      <OpenGraphTags
        title={channelName}
        imageUrl={channel?.attributes?.art?.attributes?.variants?.small}
        description={channel.attributes.description}
      />
      <IncludeQRCode />
      <NotifyNativeLoaded />
      <Helmet>
        {channel.attributes.podcast_feed_url && (
          <link
            rel="alternate"
            type="application/rss+xml"
            href={channel.attributes.podcast_feed_url}
            title={channel.attributes.podcast_settings.attributes.title}
          ></link>
        )}
      </Helmet>
      <NativeHeader
        title={channelName}
        rightType={"share"}
        shareUrl={channel.attributes.church_center_url}
      />
      <Header channel={channel} />

      {noCurrentOrUpcomingEpisodes &&
        nextDefaultTimes.length === 0 &&
        featuredEpisode && <FeaturedEpisode episode={featuredEpisode} />}
      {noCurrentOrUpcomingEpisodes && nextDefaultTimes.length > 0 && (
        <ChannelSchedule nextDefaultTimes={nextDefaultTimes} />
      )}
      {episodesAreUpcoming && (
        <NextEpisodeSchedule episodeTimes={episodeTimes} />
      )}
      {episodeIsLive && (
        <LiveEpisode
          currentOrNextEpisode={currentOrNextEpisode}
          episodeTimes={episodeTimes}
          hasLiveVideo={channel.attributes.enabled_formats.watch_live}
          hasNotes={hasNotes}
          includeds={speakers}
        />
      )}
      {featuredEpisodes.length !== 0 && (
        <>
          <div className="d-f jc-sb mb-2">
            <Heading level="2" text="Recent Episodes" />
            <Link to="episodes">View all</Link>
          </div>
          <EpisodesCardList episodes={featuredEpisodes} includeds={speakers} />
        </>
      )}
      {featuredSeries.length !== 0 && (
        <>
          <div className="mt-8 c-b d-f jc-sb mb-2">
            <Heading level="2" text="Recent Series" />
            <Link to="series">View all</Link>
          </div>
          <SeriesCardList series={featuredSeries} />
        </>
      )}
      <SubscribeMenu channel={channel} />
    </>
  )
}

Header.propTypes = { channel: PropTypes.object.isRequired }
function Header({ channel }) {
  return (
    <div className="mb-2 d-f jc-sb">
      <div className="hide-cca">
        <Heading level="1" text={channel.attributes.name} />
      </div>
    </div>
  )
}

LiveEpisode.propTypes = {
  currentOrNextEpisode: PropTypes.shape({
    attributes: shape({
      published_live_at: PropTypes.string,
      video_thumbnail_url: PropTypes.string,
      title: PropTypes.string,
    }),
    relationships: shape({
      speaker: shape({
        data: PropTypes.arrayOf(shape({ id: PropTypes.string })),
      }),
    }),
  }).isRequired,
  episodeTimes: PropTypes.array.isRequired,
  hasLiveVideo: PropTypes.bool,
  hasNotes: PropTypes.bool,
  includeds: PropTypes.arrayOf(PropTypes.object),
}
function LiveEpisode({
  currentOrNextEpisode,
  episodeTimes,
  hasLiveVideo,
  hasNotes,
  includeds,
}) {
  const ROLLOUT_episode_speakers = useFlipperFeatureEnabled(
    "ROLLOUT_episode_speakers",
  )
  const liveTimes = episodeTimes.filter(
    (episodeTime) =>
      episodeTime.attributes.current_state === EPISODE_TIME_IS_ACTIVE,
  )
  const rest = episodeTimes.filter(
    (episodeTime) =>
      episodeTime.attributes.current_state !== EPISODE_TIME_IS_ACTIVE,
  )
  const hasLiveTime = liveTimes.length > 0

  const speaker = getRelationship(
    { data: currentOrNextEpisode, included: includeds },
    "speaker",
  )?.[0]

  return (
    <div css={styles.scheduleGridContainer} className="mb-4">
      <div css={styles.videoContainer}>
        <MediaThumbnail
          alt={currentOrNextEpisode.attributes.title}
          publishedTime={currentOrNextEpisode.attributes.published_live_at}
          thumbnailUrl={
            currentOrNextEpisode.attributes.art.attributes.signed_identifier ===
            ""
              ? currentOrNextEpisode.attributes.library_video_thumbnail_url
              : currentOrNextEpisode.attributes.art.attributes.variants.medium
          }
        />
      </div>
      <div className="episode-schedule d-f fd-c g-1">
        {hasLiveTime && <span className="success-badge badge as-fs">Live</span>}
        <Heading
          level={2}
          size={3}
          text={currentOrNextEpisode.attributes.title}
        />
        {liveTimes.map((episodeTime) => (
          <div
            key={episodeTime.id}
            className="pb-2 mb-1"
            css={{
              borderBottom: "1px solid var(--color-tint6)",
              color: "var(--color-tint2)",
            }}
          >
            <div className="fs-4 pb-1">
              <Datetime start={episodeTime.attributes.starts_at} style="long" />
              {ROLLOUT_episode_speakers && speaker && (
                <span>&nbsp;&bull; {speaker.attributes.formatted_name}</span>
              )}
            </div>
            <div className="d-f g-1">
              <EpisodeTimeWatchButtons
                episode={currentOrNextEpisode}
                episodeTime={episodeTime}
                hasNotes={hasNotes}
                hasLiveVideo={hasLiveVideo}
              />
            </div>
          </div>
        ))}
        <EpisodeTimes episodeTimes={rest} />
      </div>
    </div>
  )
}

SubscribeMenu.propTypes = {
  channel: PropTypes.shape({ attributes: PropTypes.object.isRequired }),
}
export function SubscribeMenu({ channel }) {
  const {
    attributes: { podcast_settings },
  } = channel
  const { apple_podcasts_url, spotify_podcasts_url } =
    podcast_settings.attributes

  if (!apple_podcasts_url && !spotify_podcasts_url) return null

  return (
    <div className="d-f g-2 jc-c ai-c f-1 mt-3 fs-1 gc-tint8 br-4p p-1">
      <Heading level={4} text="Listen on" color="tint2" />
      {apple_podcasts_url && (
        <a
          href={apple_podcasts_url}
          title="Apple Podcasts"
          target="_blank"
          className="dropdown__item d-f ai-c c-tint2 p-0"
          rel="noreferrer"
        >
          <Icon symbol="brand#apple-podcasts" />
        </a>
      )}
      {spotify_podcasts_url && (
        <a
          href={spotify_podcasts_url}
          title="Spotify"
          target="_blank"
          className="dropdown__item d-f ai-c c-tint2 p-0"
          rel="noreferrer"
        >
          <Icon
            style={{ width: "21px", height: "21px" }}
            symbol="brand#spotify-solid"
          />
        </a>
      )}
    </div>
  )
}

FeaturedEpisode.propTypes = {
  episode: PropTypes.shape({
    attributes: shape({
      published_live_at: PropTypes.string,
      video_thumbnail_url: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired,
}
function FeaturedEpisode({ episode }) {
  const {
    attributes: {
      sermon_audio: {
        attributes: { source },
      },
    },
  } = episode

  const hasAudio = source === "hosted" || source === "remote"
  const hasVideo =
    episode.attributes.video_url || episode.attributes.library_video_url
  return (
    <div css={styles.featuredEpisodeContainer} className="mb-4 fd-rr">
      <div css={styles.videoContainer}>
        <Link
          to={episodePath(episode)}
          className="card-list-item__image"
          css={{ img: { borderRadius: 8 } }}
        >
          <MediaThumbnail
            alt={episode.attributes.title}
            publishedTime={episode.attributes.published_live_at}
            thumbnailUrl={
              episode.attributes.art.attributes.signed_identifier === ""
                ? episode.attributes.library_video_thumbnail_url
                : episode.attributes.art.attributes.variants.medium
            }
          />
        </Link>
      </div>
      <div className="episode-schedule">
        <Link to={episodePath(episode)}>
          <Heading level={2} text={episode.attributes.title} />
        </Link>

        <p className="fs-4 mt-1">
          <Date
            start={episode.attributes.published_to_library_at}
            style="standard"
            year={true}
          />
        </p>
        <p css={styles.featuredDescription}>{episode.attributes.description}</p>
        <div className="d-f ai-c lh-1 mt-1" css={{ gap: 16 }}>
          {hasVideo && (
            <Link
              to={episodePath(episode) + "?media_intent=video"}
              className="btn fs-4"
            >
              Watch
            </Link>
          )}
          {hasAudio && (
            <Link
              to={episodePath(episode) + "?media_intent=audio"}
              className={classNames("btn fs-4", {
                "text-btn": hasAudio && hasVideo,
              })}
              css={
                hasVideo
                  ? {
                      color: "var(--color-brand) !important",
                      fontWeight: "500",
                    }
                  : undefined
              }
            >
              Listen
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

NextEpisodeSchedule.propTypes = {
  episodeTimes: PropTypes.array.isRequired,
}

function NextEpisodeSchedule({ episodeTimes }) {
  return (
    <div css={styles.scheduleBanner} className="mb-4 py-3 px-2 d-f fd-c">
      <h2 className="mb-1 ta-c">
        Next Episode Live on{" "}
        <Date start={episodeTimes[0].attributes.starts_at} style="long" />
      </h2>
      <ul css={styles.inlineList} className="ta-c">
        <li className="fs-4 mb-4p pr-1">Upcoming Schedule:</li>
        {episodeTimes.map((episodeTime) => (
          <li key={episodeTime.id} className="c-tint3 px-4p mb-4p fs-4 pr-1">
            <Datetime start={episodeTime.attributes.starts_at} />
          </li>
        ))}
      </ul>
    </div>
  )
}

ChannelSchedule.propTypes = {
  nextDefaultTimes: PropTypes.array.isRequired,
}

function ChannelSchedule({ nextDefaultTimes }) {
  return (
    <div css={styles.scheduleBanner} className="mb-4 py-3 px-2">
      <h2 className="mb-1 ta-c">
        Next Live on{" "}
        <Date start={nextDefaultTimes[0].attributes.starts_at} style="long" />
      </h2>
      <ul css={styles.inlineList} className="ta-c">
        <li className="fs-4 mb-4p pr-1">Regular Times:</li>

        {nextDefaultTimes.map((nextDefaultTime) => (
          <li
            key={nextDefaultTime.id}
            className="c-tint3 px-4p mb-4p fs-4 pr-1"
          >
            Every{" "}
            <DayOfWeekAndTimeOfDay
              start={nextDefaultTime.attributes.starts_at}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

EpisodeTimes.propTypes = {
  episode: PropTypes.object.isRequired,
  episodeTimes: PropTypes.array.isRequired,
}

function EpisodeTimes({ episodeTimes }) {
  return (
    <ul css={styles.list}>
      {episodeTimes.map((episodeTime) => {
        const time = (
          <Datetime start={episodeTime.attributes.starts_at} style="long" />
        )
        return (
          <li
            css={{ alignItems: "center" }}
            className="fs-4 mb-4p c-tint0 pr-1 pb-1 d-f ai-fs ai-c@sm"
            key={episodeTime.id}
          >
            <EpisodeTimeWatchBadge episodeTime={episodeTime} />

            <div className="live-metadata">
              <span className="fs-4">{time}</span>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

EpisodeTimeWatchBadge.propTypes = {
  episodeTime: PropTypes.object.isRequired,
}

function EpisodeTimeWatchBadge({ episodeTime }) {
  const currentState = episodeTime.attributes.current_state

  switch (currentState) {
    case EPISODE_TIME_IS_OVER:
      return (
        <span className="badge ta-c mr-1" style={{ minWidth: "80px" }}>
          Over
        </span>
      )

    case EPISODE_TIME_IS_ACTIVE:
      return (
        <span
          className="success-badge badge ta-c mr-1"
          style={{ minWidth: "80px" }}
        >
          Live
        </span>
      )

    case EPISODE_TIME_IS_UPCOMING:
      return (
        <span className="badge ta-c mr-1" style={{ minWidth: "80px" }}>
          Upcoming
        </span>
      )

    default:
      throw `unexpected episode time state: ${currentState}`
  }
}

EpisodeTimeWatchButtons.propTypes = {
  episode: PropTypes.object.isRequired,
  episodeTime: PropTypes.object.isRequired,
  hasLiveVideo: PropTypes.bool,
  hasNotes: PropTypes.bool,
}

function EpisodeTimeWatchButtons({
  episode,
  episodeTime,
  hasLiveVideo,
  hasNotes,
}) {
  return (
    <>
      {hasLiveVideo && (
        <Link
          className="btn"
          to={`${episodePath(episode)}?episode_time_id=${episodeTime.id}`}
        >
          Watch Now
        </Link>
      )}
      {hasNotes && (
        <Link
          className={classNames("btn", { "secondary-btn": hasLiveVideo })}
          to={`${episodePath(episode)}/notes`}
        >
          Take Notes
        </Link>
      )}
    </>
  )
}

Library.propTypes = {
  children: PropTypes.element,
  excludeId: PropTypes.string,
  queryParams: PropTypes.object.isRequired,
}

export function Library({ queryParams, excludeId, children }) {
  const ROLLOUT_episode_speakers = useFlipperFeatureEnabled(
    "ROLLOUT_episode_speakers",
  )

  const perPage = 30
  const loadManuallyAfterThisOffset = 60
  const [offset, setOffset] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [libraryEpisodes, setLibraryEpisodes] = useState([])
  const [libraryIncludeds, setLibraryIncludeds] = useState([])
  const [hideComponent, setHideComponent] = useState(false)

  const loadMore = () => {
    setHasMore(false)
    setOffset((previousOffset) => previousOffset + perPage)
  }

  const allParams = Object.assign(
    {},
    {
      per_page: perPage,
      offset,
    },
    ROLLOUT_episode_speakers ? { include: "speakerships.speaker" } : null,
    queryParams,
  )

  if (excludeId) {
    allParams.per_page = allParams.per_page + 1
  }
  const queryString = new URLSearchParams(allParams).toString()

  function removeExcludedEpisode(data) {
    const pageLength = queryParams.per_page || perPage
    return excludeId
      ? data.filter((episode) => episode.id !== excludeId).slice(0, pageLength)
      : data
  }

  useEffect(() => {
    let mounted = true
    setLoadingMore(true)
    sessionApiClient
      .get(`/publishing/v2/episodes?${queryString}`)
      .then((payload) => {
        const episodesWithoutExcludedEpisode = removeExcludedEpisode(
          payload.data,
        )

        if (!mounted) return false

        if (episodesWithoutExcludedEpisode.length === 0) {
          setLibraryEpisodes([])
          setLibraryIncludeds([])
          setHideComponent(true)
        } else {
          setHasMore(!!payload.links.next)
          setLibraryEpisodes((previousLibraryEpisodes) => [
            ...previousLibraryEpisodes,
            ...episodesWithoutExcludedEpisode,
          ])
          setLibraryIncludeds((prev) => [...prev, ...(payload.included || [])])
        }

        setLoadingMore(false)
      })
      .catch((error) => {
        setLoadingMore(false)
        console.log(`There was an error fetching more episodes: ${error}`) // eslint-disable-line no-console
      })

    return () => {
      mounted = false
    }
  }, [queryString])

  if (hideComponent) return null

  return (
    <>
      {children}
      <LimitedInfiniteScroll
        loadMoreButtonText="View more"
        keepLoadingOnScroll={!excludeId && offset < loadManuallyAfterThisOffset}
        hasMore={!excludeId && hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
      >
        <EpisodesCardList
          episodes={libraryEpisodes}
          includeds={libraryIncludeds}
        />
      </LimitedInfiniteScroll>
    </>
  )
}

const styles = {
  featuredEpisodeContainer: css`
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
  `,
  featuredDescription: css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  scheduleGridContainer: css`
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
  `,
  videoContainer: css`
    border-radius: 4px;
  `,
  scheduleBanner: css`
    background-color: var(--color-tint8);
    border-radius: 4px;
  `,
  inlineList: css`
    list-style: none;
    margin: 0 ${size(2)}px 0 0;
    padding: 0;
    li {
      display: inline-block;
    }
  `,
  list: css`
    list-style: none;
    margin: 0;
    padding: 0;

    .live-metadata {
      display: flex;
      @media (max-width: 719px) {
        position: relative;
        flex-direction: column;

        a {
          margin-left: 0;
        }
      }
    }
  `,
}

EpisodesCardList.propTypes = {
  episodes: PropTypes.arrayOf(PropTypes.object),
  includeds: PropTypes.arrayOf(PropTypes.object),
}
function EpisodesCardList({ episodes, includeds }) {
  const ROLLOUT_episode_speakers = useFlipperFeatureEnabled(
    "ROLLOUT_episode_speakers",
  )

  return (
    <CardGrid>
      {episodes.map((episode) => {
        const speaker = getRelationship(
          { data: episode, included: includeds },
          "speaker",
        )?.[0]

        return (
          <Card
            key={episode.id}
            link={{ as: Link, to: episodePath(episode) }}
            poster={
              <MediaThumbnail
                alt={episode.attributes.title}
                publishedTime={episode.attributes.published_to_library_at}
                thumbnailUrl={
                  episode.attributes.art.attributes.signed_identifier === ""
                    ? episode.attributes.library_video_thumbnail_url
                    : episode.attributes.art.attributes.variants.medium
                }
              />
            }
            heading={episode.attributes.title}
            description={
              <>
                <Date
                  start={episode.attributes.published_to_library_at}
                  style="standard"
                  year={true}
                />
                {ROLLOUT_episode_speakers && speaker && (
                  <span>&nbsp;&bull; {speaker.attributes.formatted_name}</span>
                )}
              </>
            }
          />
        )
      })}
    </CardGrid>
  )
}

SeriesCardList.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object),
}
export function SeriesCardList({ series }) {
  return (
    <CardGrid>
      {series.map((series) => (
        <Card
          key={series.id}
          link={{ as: Link, to: series.attributes.church_center_path }}
          poster={series.attributes.art.attributes.variants.medium}
          heading={series.attributes.title}
          description={
            series.attributes.started_at && (
              <Date
                start={series.attributes.started_at}
                end={series.attributes.ended_at}
                style="standard"
                year={true}
              />
            )
          }
        />
      ))}
    </CardGrid>
  )
}
