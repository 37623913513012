import { Link, useParams } from "react-router-dom"
import { date } from "@churchcenter/datetime-fmt"
import moment from "moment"
import {
  BreadcrumbNavigation,
  BreadcrumbContent,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import { useApiRead } from "source/shared/SessionApiResource"
import { EventDatetimeDisplay } from "source/shared/DatetimeFmt"
import { getRelationship } from "source/shared/getRelationship"
import { LocationDetails } from "source/groups/components/LocationDetails"
import { Heading, Subheading } from "@planningcenter/doxy-web"
import { object, array } from "prop-types"
import BlankState from "source/groups/BlankState"

export default function EventsShow() {
  const { data: event, included, errors } = useEvent()

  if (errors) {
    return (
      <BlankState icon="churchCenter#groups-o">
        <p className="mb-2">This event is currently unavailable</p>
        <Link to="/groups" className="mr-1 minor-btn secondary-btn btn">
          View all Groups
        </Link>
      </BlankState>
    )
  }

  return (
    <>
      <BreadcrumbNav {...{ event, included }} />
      <Event {...{ event }} />
    </>
  )
}

function BreadcrumbNav({ event, included }) {
  const { name: eventName } = event.attributes
  const group = getRelationship({ data: event, included }, "group")
  const { church_center_web_url, name: groupName } = group.attributes
  const relativeGroupLink = new URL(church_center_web_url).pathname

  return (
    <BreadcrumbNavigation>
      <BreadcrumbPage linkUrl={relativeGroupLink} pageText={groupName} />
      <BreadcrumbDividerIcon />
      <BreadcrumbContent isActive>{eventName}</BreadcrumbContent>
    </BreadcrumbNavigation>
  )
}

BreadcrumbNav.propTypes = {
  event: object.isRequired,
  included: array.isRequired,
}

function Event({ event }) {
  const {
    starts_at,
    ends_at,
    canceled_at,
    name,
    description,
    location_type_preference,
    virtual_location_url,
  } = event.attributes

  const [shortMonthName, dayNumber] = date(starts_at, {
    style: "abbreviated",
  }).split(" ")
  const eventIsCanceled = canceled_at && moment(canceled_at).isBefore(moment())

  return (
    <>
      <header className="d-f mb-3 pt-1 ai-c">
        <div className="mr-2 event-detail__cal">
          <div className="ta-c event-cal">
            <div className="event-cal__month">{shortMonthName}</div>
            <div className="event-cal__day">{dayNumber}</div>
          </div>
        </div>

        <div className="f-1">
          <div className="d-f ai-c">
            <Heading level={1} size={2} text={name} />
            {eventIsCanceled && (
              <div className="badge danger-badge ml-1">Canceled</div>
            )}
          </div>
          <div className="mb-4p c-tint2">
            <EventDatetimeDisplay startsAt={starts_at} endsAt={ends_at} />
          </div>
        </div>
      </header>

      {description && (
        <div className="mb-3">
          <div className="mb-1">
            <Subheading level={2} text="Details" />
          </div>
          <div data-preformatted-content="heading-scale:legacy-church-center">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      )}

      <div className="mb-3 w-33%@md">
        <LocationDetails
          preference={location_type_preference}
          virtual={{ url: virtual_location_url }}
          physical={location.attributes}
        />
      </div>
    </>
  )
}

Event.propTypes = {
  event: object.isRequired,
}

function useEvent() {
  const { eventId } = useParams()
  return useApiRead(
    `/groups/v2/events/${eventId}?include=group&fields[Group]=church_center_web_url,name`,
  )
}
