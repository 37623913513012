import { useParams } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import { Library } from "source/publishing/sermons/Channel"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import useNotifyNativeLoaded from "source/shared/hooks/useNotifyNativeLoaded"
import { Heading } from "@planningcenter/doxy-web"
import { Error404 } from "source/shared/components/Error404"
import NativeHeader from "./shared/NativeHeader"

export function EpisodeList() {
  const { channelId } = useParams()
  const response = useApiRead(`/publishing/v2/channels/${channelId}/`)

  useNotifyNativeLoaded()

  if (response.errors?.[0]?.status === "404") return <Error404 />

  const channel = response.data

  return (
    <div className="d-f fd-c g-2">
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/channels/${channelId}`}
          pageText={channel.attributes.name}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="Episodes" isActive />
      </BreadcrumbNavigation>
      <NativeHeader
        title={channel.attributes.name}
        rightType={"share"}
        shareUrl={`${channel.attributes.church_center_url}/episodes`}
      />
      <Heading level={2} text="Episodes" />
      <Library
        queryParams={{
          "where[channel_id]": channelId,
          filter: "published_to_library",
          order: "-published_to_library_at",
        }}
      />
    </div>
  )
}
