import { useContext } from "react"
import { Navigate, Routes, Route, useLocation } from "react-router-dom"
import { Login, Logout } from "source/login"
import { Home } from "source/home"
import { Households } from "source/myChurchCenter/Profile/households"
import { Unproxy, HardRefresh } from "source/shared/routingUtils"
import { Calendar } from "source/calendar"
import { Giving } from "source/giving"
import { Groups } from "source/groups"
import { MyGroupRouter } from "source/groups/my/groups"
import { SermonChannels, SermonEpisodes } from "source/publishing/sermons"
import { CustomPage } from "source/publishing/Page"
import { People, Messages } from "source/people"
import { Registrations } from "source/registrations"
import { Services } from "source/services"
import { MyChurchCenter } from "source/myChurchCenter"
import { CheckIns } from "source/check-ins"
import { Notifications } from "source/notifications"
import { Directory } from "source/directory"
import { Error404 } from "source/shared/components/Error404"
import { WebBootContext } from "source/publishing/WebBoot"
import { Schedule } from "source/schedule"
import { useUpdateEffect } from "./shared/hooks/useUpdateEffect"

// As part of @reach/router's baked-in accessibility features, the router will
// automatically focus the element `navigate()` has loaded, and browsers
// will scroll the focused element into view. This results in
// unpredictable behavior of the Church Center header that we'd like to avoid,
// so the route change worker is a workaround to keep the page scrolled to top
// after a `navigate()` action.
//
// So far, it doesn't sound like the reach folks think this is a bug or that
// they are interested in addressing in the library. ¯\_(ツ)_/¯
//
// https://github.com/reach/router/issues/62
// https://github.com/reach/router/issues/242

const ScrollToTopOnRouteChange = () => {
  const location = useLocation()
  useUpdateEffect(() => window.scrollTo(0, 0), [location.pathname])

  return null
}

export function MainRouter() {
  const {
    builtInPages,
    draftMode,
    draftMenu: {
      attributes: { start_page_path: draftStartPagePath },
    },
    publishedMenu: {
      attributes: { start_page_path: publishedStartPagePath },
    },
  } = useContext(WebBootContext)

  let isPublished = (identifier) => builtInPages[identifier] === "published"

  // If you add things to the Router here, you also must add
  // them to the `SinglePageAppFirstSegmentConstraint`
  return (
    <>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="households/*" element={<Households />} />
        {isPublished("check-ins") && (
          <Route path="/check-ins/*" element={<CheckIns />} />
        )}
        {isPublished("directory") && (
          <Route path="/directory/*" element={<Directory />} />
        )}
        {isPublished("giving") && (
          <Route path="/giving/*" element={<Giving />} />
        )}
        {isPublished("groups") && (
          <>
            <Route path="/groups/*" element={<Groups />} />
            <Route path="/my/groups/:slug/*" element={<MyGroupRouter />} />
          </>
        )}
        <Route path="/me/*" element={<MyChurchCenter />} />
        {/* TODO: remove this redirect after 11/1/2024 */}
        <Route
          path="/profile/*"
          element={<Navigate to={"/me/profile"} replace />}
        />
        {isPublished("registrations") && (
          <Route path="/registrations/*" element={<Registrations />} />
        )}
        <Route path="/calendar/*" element={<Calendar />} />
        <Route path="/schedule/*" element={<Schedule />} />
        <Route path="/services/*" element={<Services />} />
        <Route path="/channels/*" element={<SermonChannels />} />
        <Route path="/episodes/*" element={<SermonEpisodes />} />
        <Route path="/people/*" element={<People />} />
        <Route path="/notifications/*" element={<Notifications />} />
        <Route path="/messages/*" element={<Messages />} />
        <Route path="/pages/home" element={<Navigate to={"/home"} replace />} />
        <Route path="/pages/:slug" element={<CustomPage />} />

        <Route
          path="/"
          element={
            <Navigate
              to={draftMode ? draftStartPagePath : publishedStartPagePath}
              replace
            />
          }
        />

        <Route path="/unproxy/*" element={<Unproxy />} />
        <Route path="/setup" element={<HardRefresh />} />
        <Route path="*" element={<Error404 />} />
      </Routes>

      <ScrollToTopOnRouteChange />
    </>
  )
}
