import { Fragment, useEffect, useState } from "react"
import { CountrySelect } from "../CountrySelect"
import { TextInput } from "@planningcenter/doxy-web"
import { Icon } from "source/shared/components"
import { LocationSelect } from "../LocationSelect"
import { bool, func, object } from "prop-types"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox"
import "@reach/combobox/styles.css"
import { useDebounce } from "source/shared/hooks/useDebounce"

Address.propTypes = {
  address: object.isRequired,
  canEditContactInfo: bool.isRequired,
  saving: bool.isRequired,
  markForDelete: func.isRequired,
  unmarkForDelete: func.isRequired,
  updateAttribute: func.isRequired,
  domId: func.isRequired,
  isNewRecord: func.isRequired,
  initialCountry: object,
}

export function Address({
  address,
  canEditContactInfo,
  saving,
  markForDelete,
  unmarkForDelete,
  updateAttribute,
  domId,
  isNewRecord,
  initialCountry: _initialCountry,
}) {
  const [country, setCountry] = useState(_initialCountry)
  const [initialCountry] = useState(_initialCountry)
  const [autocompletedAddresses, setAutocompletedAddresses] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const isCountryDifferentThanInitial =
    initialCountry?.id !== address.attributes.country_code

  useEffect(() => {
    // fetch the country field details when country changes
    if (address.attributes.country_code && isCountryDifferentThanInitial) {
      sessionApiClient
        .get(`/people/v2/countries/${address.attributes.country_code}`, {
          "fields[Country]": "address_fields,name",
        })
        .then((response) => {
          setCountry(response.data)
        })
    } else {
      // use initial cached country
      setCountry(initialCountry)
    }
  }, [
    address.attributes.country_code,
    isCountryDifferentThanInitial,
    initialCountry,
  ])

  useEffect(() => {
    sessionApiClient
      .post(`/people/v2/address_autocomplete`, {
        data: {
          attributes: {
            query: debouncedSearchTerm,
          },
        },
      })
      .then(({ data }) => {
        const autocompletedAddresses = data.map(
          ({ attributes: { text, place_identifier } }) => {
            return {
              name: text,
              place_identifier,
            }
          },
        )
        setAutocompletedAddresses(autocompletedAddresses)
      })
  }, [debouncedSearchTerm])

  function getPlaceholder(fieldName) {
    if (country) {
      const fieldNames = country.attributes.address_fields
      switch (fieldName) {
        case "street_line_1":
          return fieldNames[0][0].label
        case "street_line_2":
          return fieldNames[1][0].label
        case "city":
          return fieldNames[2][0].label
        case "state":
          return fieldNames[2][1].label
        case "zip":
          return fieldNames[2][2].label
      }
    }
  }

  function onAutocompletionSelect(place_identifier) {
    setSearchTerm("")
    setAutocompletedAddresses([])

    sessionApiClient
      .post(`/people/v2/autocomplete_detail`, {
        data: {
          attributes: {
            place_identifier,
          },
        },
      })
      .then(({ data }) => {
        const {
          attributes: {
            city,
            country_code,
            state,
            street_line_1,
            street_line_2,
            zip,
          },
        } = data

        updateAttribute(
          address,
          "street",
          street_line_1 + (street_line_2 || ""),
        )
        updateAttribute(address, "city", city)
        updateAttribute(address, "state", state)
        updateAttribute(address, "zip", zip)
        updateAttribute(address, "country_code", country_code)
      })
  }

  return (
    <div css={styles.editingFieldContainer} key={address.id}>
      {address.deleting ? (
        <Fragment>
          <div className="c-tint3 fs-5">{address.attributes.location}</div>
          <div>
            <strike
              aria-label={`address to be deleted: ${
                address.attributes.street
              } ${address.attributes.city} ${address.attributes.state} ${
                address.attributes.zip
              } ${address.attributes.country_code && country?.attributes.name}`}
            >
              {address.attributes.street}
              <br />
              {address.attributes.city}, {address.attributes.state}{" "}
              {address.attributes.zip}
              {address.attributes.country_code && (
                <>
                  <br />
                  {country?.attributes.name}
                </>
              )}
            </strike>
            <button
              type="button"
              disabled={!canEditContactInfo || saving}
              className="fw-500 ml-1 c-topaz stripped-btn p-0"
              aria-label={`restore address: ${address.attributes.street} ${
                address.attributes.city
              } ${address.attributes.state} ${address.attributes.zip} ${
                address.attributes.country_code && country?.attributes.name
              }`}
              onClick={() => unmarkForDelete(address)}
            >
              Restore
            </button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="d-f@sm ai-fs">
            <LocationSelect
              disabled={!canEditContactInfo || saving}
              id={domId(address)}
              value={address.attributes.location}
              onChange={(value) => updateAttribute(address, "location", value)}
            />
            <div className="f-1 pl-1@sm">
              <div className="d-f">
                <CountrySelect
                  disabled={!canEditContactInfo || saving}
                  id={domId(address, "country_code")}
                  value={address.attributes.country_code}
                  onChange={(value) =>
                    updateAttribute(address, "country_code", value)
                  }
                />
                <div className="f-1">
                  <label htmlFor={domId(address, "street_line_1")}>
                    <span className="screen-reader-text">
                      {getPlaceholder("street_line_1") || "Address line 1"}
                    </span>
                    <Combobox onSelect={onAutocompletionSelect}>
                      <ComboboxInput
                        autoFocus={isNewRecord(address)}
                        disabled={!canEditContactInfo || saving}
                        type="text"
                        placeholder={
                          getPlaceholder("street_line_1") || "Address line 1"
                        }
                        id={domId(address, "street_line_1")}
                        value={
                          address.attributes.street
                            ? address.attributes.street.split("\n")[0]
                            : ""
                        }
                        autocomplete={false}
                        onChange={({ target: { value } }) => {
                          setSearchTerm(value)

                          if (!address.attributes.street)
                            return updateAttribute(address, "street", value)

                          const [_, line2] =
                            address.attributes.street.split("\n")

                          const newStreet = line2
                            ? value.concat("\n", line2)
                            : value
                          updateAttribute(address, "street", newStreet)
                        }}
                      />
                      <ComboboxPopover>
                        <ComboboxList>
                          {autocompletedAddresses.map(
                            ({ name, place_identifier }) => (
                              <ComboboxOption
                                key={`address-${place_identifier}`}
                                value={place_identifier}
                              >
                                {name}
                              </ComboboxOption>
                            ),
                          )}
                        </ComboboxList>
                      </ComboboxPopover>
                    </Combobox>
                  </label>
                </div>
              </div>
              <label htmlFor={domId(address, "street_line_2")}>
                <span className="screen-reader-text">
                  {getPlaceholder("street_line_2") || "Address line 2"}
                </span>
                <TextInput
                  id={domId(address, "street_line_2")}
                  placeholder={
                    getPlaceholder("street_line_2") || "Address line 2"
                  }
                  disabled={!canEditContactInfo || saving}
                  value={
                    address.attributes.street
                      ? address.attributes.street.split("\n")[1]
                      : ""
                  }
                  onChange={({ target: { value } }) => {
                    const [line1] = address.attributes.street.split("\n")
                    const newStreet =
                      value === "" ? line1 : line1.concat("\n", value)
                    updateAttribute(address, "street", newStreet)
                  }}
                />
              </label>

              <div className="d-f ai-c">
                <label className="f-2" htmlFor={domId(address, "city")}>
                  <span className="screen-reader-text">
                    {getPlaceholder("city") || "City"}
                  </span>
                  <TextInput
                    id={domId(address, "city")}
                    placeholder={getPlaceholder("city") || "City"}
                    disabled={!canEditContactInfo || saving}
                    value={address.attributes.city}
                    onChange={(e) =>
                      updateAttribute(address, "city", e.target.value)
                    }
                  />
                </label>
                <label className="f-1 mx-1" htmlFor={domId(address, "state")}>
                  <span className="screen-reader-text">
                    {getPlaceholder("state") || "State/Province/Region"}
                  </span>
                  <TextInput
                    id={domId(address, "state")}
                    placeholder={
                      getPlaceholder("state") || "State/Province/Region"
                    }
                    disabled={!canEditContactInfo || saving}
                    value={address.attributes.state}
                    onChange={(e) =>
                      updateAttribute(address, "state", e.target.value)
                    }
                  />
                </label>
                <label className="f-1" htmlFor={domId(address, "zip")}>
                  <span className="screen-reader-text">
                    {getPlaceholder("zip") || "Postal Code"}
                  </span>
                  <TextInput
                    id={domId(address, "zip")}
                    placeholder={getPlaceholder("zip") || "Postal Code"}
                    disabled={!canEditContactInfo || saving}
                    value={address.attributes.zip}
                    onChange={(e) =>
                      updateAttribute(address, "zip", e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="ta-r">
            <button
              type="button"
              disabled={!canEditContactInfo || saving}
              className="destroy-btn minor-compact-btn btn"
              aria-label={`mark address for deletion: ${
                address.attributes.street
              } ${address.attributes.city} ${address.attributes.state} ${
                address.attributes.zip
              } ${address.attributes.country_code && country?.attributes.name}`}
              onClick={() => markForDelete(address)}
            >
              <span className="fs-6 mr-4p" aria-hidden="true">
                <Icon symbol="general#x" />
              </span>
              Delete
            </button>
          </div>
        </Fragment>
      )}
    </div>
  )
}

const styles = {
  editingFieldContainer: {
    paddingTop: "16px",

    "&:first-of-type": { paddingTop: "8px" },
  },
}
