import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSuspenseQuery } from "@tanstack/react-query"

const CC_CONFIG_PATH = "/calendar/v2/church_center_configuration"

type View = "month" | "list" | "gallery"

type ApiResponse = {
  data: {
    attributes: {
      default_view: View
      available_views: Array<View>
    }
  }
}

interface Configuration {
  defaultView: View
  availableViews: Array<View>
}

export function useChurchCenterConfiguration(): Configuration {
  const { data } = useSuspenseQuery<ApiResponse>({
    queryKey: [CC_CONFIG_PATH],
    queryFn: () => {
      return sessionApiClient.get(CC_CONFIG_PATH)
    },
    retry: 5,
    retryDelay: (retryCount) => (retryCount + 1) * 1000,
  })

  const { default_view: defaultView, available_views: availableViews } =
    data.data.attributes

  return {
    defaultView,
    availableViews,
  } as Configuration
}
